// import Flags from "../../images/flags.png"
import {Flags} from "../../Data/export_images"

function CountryAcknowledgement() {
    const rootPath = window.location.origin;
    return (
            <div className='flex lg:flex-wrap justify-center space-x-4 2xl:gap-x-10 items-center h-60 max-lg:h-40 max-lg:order-2 max-lg:flex-col max-lg:gap-y-5'>
                <img src={rootPath + "/" + Flags} alt="IFFA-flag" className="h-10 w-auto" />
                <div className="w-60 h-28 2xl:h-44 2xl:w-80 2xl: overflow-auto">
                    <p className="flex-grow text-justify text-gray-400 xs:text-base text-base max-md:text-sm max-w-80 min-w-60 m-0">
                        We acknowledge the Traditional Owners of the land where we work and live. We pay our respects
                        to Elders past, present and emerging. We celebrate the stories, culture and traditions of
                        Aboriginal and Torres Strait Islander Elders of all communities who also work and live on
                        this land.
                    </p>
                </div>
            </div>
    )
}

export default CountryAcknowledgement;