import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import NewsCard from "../Components/NewsandPartners/NewsCard";

import { motion } from 'motion/react';
import { FilmFocusLogo, FilmInkLogo, LondonDailyNewsLogo, PartnersBackground } from '../Data/export_images';

const Partners = () => {
  const partnersData = [
    {
      alt: "RMIT",
      description: "RMIT University",
      url: "https://www.rmit.edu.au/",
    },
    {
      alt: "TORRENS",
      description: "Torrens University Australia",
      url: "https://torrens.edu.au/",
    },
    {
      alt: "FORDHAM PR",
      description: "Fordham PR",
      url: "http://www.fordhampr.ca/introducing-the-international-film-festival-of-australia/",
    },
    {
      alt: "FIFTY ACRES",
      description: "Fifty Acres",
      url: "https://fiftyacres.com.au/?utm_source=google&utm_medium=cpc&utm_campaign=PPC_Feb_June_22&gad_source=1&gbraid=0AAAAAobAlNbsvWs0zY9mgmumg2WE3dMIf&gclid=CjwKCAjw8fu1BhBsEiwAwDrsjCq_Iy_CI-cUlXdeGY_m7QnK5etUMJ2eQQJ9IU6C9bSWVCwvohBaZhoCqyYQAvD_BwE",
    },
    {
      alt: "Istituto Italiano di Cultura di Melbourne",
      description: "Istituto Italiano di Cultura di Melbourne",
      url: "",
    },
    {
      alt: "Consulate of the Republic of Korea",
      description: "Consulate of the Republic of Korea",
      url: "",
    },
    {
      alt: "Creative Production Studio",
      description: "Creative Production Studio",
      url: "",
    },
    {
      alt: "Universal Entertainment",
      description: "Universal Entertainment",
      url: "",
    },
  ];

  const newsData = [
    {
      link: "https://www.filmfocusmagazine.com/the-feed/iffa-awards-2024",
      companyName: "Film Focus Magazine",
      // category: "Entertainment",
      description:
        "Explore the IIFA Awards 2024 winners, including Dev Patel, Daniel Wu, Sunny Deol, and more!",
      date: "November 23 2024",
      author: "Jacob Richardson",
    },
    {
      link: "https://www.filmink.com.au/public-notice/just-a-farmer-takes-home-three-major-awards-at-the-2024-iffa-awards/",
      companyName: "Film Ink",
      // category: "Entertainment",
      description:
        "Explore the IIFA Awards 2024 winners, including Dev Patel, Daniel Wu, Sunny Deol, and more!",
      date: "March 25 2024",
      author: "Film Ink",
    },
    {
      link: "http://www.fordhampr.ca/congrats-to-all-the-iffa-winners-award-show-producers-down-under/",
      companyName: "Fordham PR",
      // category: "Entertainment",
      description:
        "Hollywood comes to Melbourne: James Pratt, Cody Simpson, Louise Child, and Samantha Coughlan among stars at this year’s IFFA awards.",
      date: "Novemebr 14 2024",
      author: "Fordham PR",
    },
    {
      link: "/newsPDF/HeraldSun.pdf",
      companyName: "Herald Sun",
      description:
        "Celebrated exception IFFA Awards Festival on November 5 2024.",
      date: "Novemebr 5 2024",
      author: "GraceBaldwin",
    },
    {
      link: "https://www.londondaily.news/dev-patel-daniel-wu-jj-pantano-sunny-deol-and-cristiana-dellanna-among-2024-iffa-award-winners/",
      companyName: "London Daily News",
      // category: "Entertainment",
      description:
        "Explore the IIFA Awards 2024 winners, including Dev Patel, Daniel Wu, Sunny Deol, and more!",
      date: "November 18 2024",
      author: "London Daily News",
    },
    
    {
      link: "https://www.londondaily.news/hollywood-comes-to-melbourne-james-pratt-cody-simpson-louise-child-and-samantha-coughlan-among-stars-at-this-years-iffa-awards/",
      companyName: "London Daily News",
      // category: "Entertainment",
      description:
        "Hollywood comes to Melbourne: James Pratt, Cody Simpson, Louise Child, and Samantha Coughlan among stars at this year’s IFFA awards.",
      date: "November 5 2024",
      author: "Chris Hamsworth",
    },
    {
      link: "/newsPDF/Moreawardsforlocalcrew.pdf",
      companyName: "Shortcuts",
      description:
        "More awards coming in for the local crew.",
      date: "Novemebr 14 2024",
      author: "Shortcuts",
    },
    {
      link: "/newsPDF/Filmgainsglobalsuccess.pdf",
      companyName: "Shortcuts",
      description:
        "Film gains global success.",
      date: "Novemebr 26 2024",
      author: "Sheryl Lowe",
    },  

  ];

  return (
    <div className="bg-[black] mx-[auto] my-[0] font-[Arial,_sans-serif]">
      <Navbar />
      <motion.main
        initial={{ opacity: 0, x: -150 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.5, delay: 1 }}
      >
        <header className="relative flex items-center justify-center h-44 text-white">
          <h1 className="z-20 shadow-2xl shadow-white rounded-lg px-5 py-2 font-bold text-4xl">
            NEWS AND PARTNERS
          </h1>
          <div className="absolute z-10 inset-0 bg-black opacity-50"></div>
          <img
            src={PartnersBackground}
            className="absolute top-0 h-full w-full object-cover"
          ></img>
        </header>

        <section className="partners-section text-center mb-[50px] text-[4rem] font-semibold text-[#f1f1f1] tracking-[2px]">
          <div className="partners-grid max-w-[1000px] grid text-center mr-auto ml-auto grid-cols-[repeat(2,_1fr)] max-sm:grid-cols-1 gap-[50px 50px]">
            {partnersData.map((partner, index) =>
              partner.url !== "" ? (
                <a
                  href={partner.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <div className="partner p-[20px] [transition:transform_0.3s_ease,_color_0.3s_ease] hover:scale-125">
                    <p className="text-[30px] text-[#d0d0d0] my-[20px] border-b-2 border-solid border-[#d0d0d0] pb-[20px] transition-colors duration-500 hover:text-[#d3a955] hover:border-[#d3a955]">
                      {partner.description}
                    </p>
                  </div>
                </a>
              ) : (
                <div key={index}>
                  <div className="partner p-[20px] [transition:transform_0.3s_ease,_color_0.3s_ease] hover:scale-125">
                    <p className="text-[30px] text-[#d0d0d0] my-[20px] border-b-2 border-solid border-[#d0d0d0] pb-[20px] transition-colors duration-500 hover:text-[#d3a955] hover:border-[#d3a955]">
                      {partner.description}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </section>

        <section className="news-section text-center mb-16">
          <h2 className="text-[3rem] bg-black text-[#f1f1f1] font-semibold mb-10 py-4">
            Latest Stories
          </h2>

          <div className="news-grid max-w-[1200px] mx-auto grid grid-cols-[repeat(auto-fit,_minmax(300px,_1fr))] gap-10">
            {newsData.map((news, index) => (
              <NewsCard
                key={index}
                link={news.link}
                companyName={news.companyName}
                // category={news.category}
                description={news.description}
                date={news.date}
                author={news.author}
              />
            ))}
          </div>
        </section>
      </motion.main>

      <Footer />
    </div>
  );
};

export default Partners;

/* Responsive Styles */
// @media (max-width: 768px) {
//     .partner-reponsive-area{
//         height: 35px;
//     }

//     .partners-header {

//         height: 150px;
//     }

//     .partners-header h1 {

//         font-size: 2.5rem;
//     }

//     .partners-section h2 {
//         font-size: 2.5rem;
//         margin-bottom: 30px;
//     }

//     .partners-grid {

//         grid-template-columns: 1fr;
//         gap: 30px;
//     }

//     .partner {
//         padding: 15px;
//     }

//     .partner p {
//         font-size: 1.5rem;
//     }

//     .partner img {
//         height: 150px;
//     }
// }

// @media (max-width: 480px) {
//     .partner-reponsive-area{
//         height: 30px;
//     }
//     .partners-header {
//         height: 120px;
//     }

//     .partners-header h1 {
//         font-size: 2rem;
//     }

//     .partners-section h2 {
//         font-size: 2rem;
//         margin-bottom: 20px;
//     }

//     .partner p {
//         font-size: 1.2rem;
//     }

//     .partner img {
//         height: 120px;
//     }
// }
