import { motion } from "motion/react"
import { Link } from "react-router-dom"
import { MonkeyManTrailerCropped } from "../../../Data/export_videos"
import { TitleSVG } from "../../../Data/export_images"
import Navbar from "../../Navbar/Navbar"
import { HeroSectionProps } from "./HeroSection"

const MobileHeroSection = ({videoBackground} : HeroSectionProps) => {
    return (
        <div className="relative flex flex-col py-10 justify-center items-center sm:hidden h-[475px] ">
            <Navbar isHomePage/>
            <video
                autoPlay
                loop
                muted
                className="absolute top-0 w-full h-[500px] max-xl:object-fill"
            >
                <source src={videoBackground} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='absolute h-[500px] top-0 inset-0 bg-black opacity-50'/>
            <div className="flex flex-col items-center gap-y-10 z-10">
                <div className="z-10 flex flex-col items-center text-light-gold text-center font-bold gap-y-5">
                    <h1 className="text-3xl xl:text-5xl">IFFA Award Ceremony</h1>
                    <h1 className="text-2xl xl:text-4xl">5 November 2024</h1>
                </div>
                {/* <motion.div
                    initial="hidden"
                    animate="visible"
                    // variants={floatVariants}
                    whileHover={{ scale: 1.15 }}
                    transition={{ type: 'spring', stiffness: 300 }}
                    className="flex items-center justify-center text-white w-fit px-5 xl:w-64 border-2 border-white font-bold text-center py-3 xl:py-6 transition-all duration-500 ease-in-out rounded-xl"
                    style={{ willChange: "transform" }} // Ensure smooth rendering
                    // className
                >
                    <Link to="https://www.ticketmaster.com.au/international-film-festival-tickets/artist/1347704" className="text-xl">Get Tickets</Link>
                </motion.div> */}
            </div>
            
        </div>
    )
}

export default MobileHeroSection;