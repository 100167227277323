import { useParams } from "react-router-dom";
import { winnersList } from "../Data/winners";
import ReactMarkdown from "react-markdown";


const Biography = () => {
  const { winnerId } = useParams();
  const winner = winnersList.find((w) => w.id === parseInt(winnerId || ''));
  console.log("Winner Object: ", winner);

  


  if (!winner) {
    return <div>Winner not found!</div>;
  }

  return (
    <section className="bg-black text-white py-20">
      <div className="container mx-auto px-6">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-light-gold mb-10">
          {winner.name}'s Biography
        </h1>
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="flex-1">
            <img
              src={ "/" + winner.imageDir}  // Fallback image if necessary
              alt={winner.name}
              className="w-full rounded-lg shadow-lg"
            />
          </div>


          <div className="flex-1">
            <p className="text-lg md:text-xl text-white font-semibold leading-relaxed mb-6 text-justify" style={{ whiteSpace: "pre-wrap" }}>
              {winner.biography}
            </p>
            {winner.link && (
              <div className="text-center">
                <a
                  href={winner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block text-xl font-semibold text-light-gold hover:text-gold-400 transition"
                >
                  Visit {winner.name}'s IMDb Profile
                </a>
              </div>
            )}
            <br></br>
            {/* Additional Links Section */}
            {winner.additionalLinks && winner.additionalLinks.map((link, index) => (
              <div key={index} className="text-center mb-4">
                <a
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block text-xl font-semibold text-light-gold hover:text-gold-400 transition"
                >
                  {link.label}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Biography;
