import { motion } from "motion/react";
import { Film } from "../../../Data/films";
import { useState } from "react";
import { useNavigate } from "react-router";

type FilmCardProps = {
    film: Film;
};

function convertToThumbnailUrl(googleDriveUrl: string, width = 1000): string {
    let fileId;

    const matchPath = googleDriveUrl.match(/\/d\/(.+?)\//);
    if (matchPath) {
        fileId = matchPath[1];
    } else {
        const matchQuery = googleDriveUrl.match(/id=([^&]+)/);
        if (matchQuery) {
            fileId = matchQuery[1];
        }
    }

    if (!fileId) {
        console.error('Invalid Google Drive URL:', googleDriveUrl);
        return googleDriveUrl;
    }

    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w${width}`;
}



function FilmCardCarousel({ ...props }: FilmCardProps) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    const handleFilmClick = (film: Film) => {
        navigate('/synopsis/' + film.filmId);
    }

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: {
            opacity: 1,
            y: 0,
        }
    }

    // const thumbnailUrl = convertToThumbnailUrl(props.film.poster);

    return (
        <motion.div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => handleFilmClick(props.film)}
            className="relative hover:shadow-xl hover:shadow-white max-sm:min-w-[200px] max-sm:w-[200px] max-sm:h-[266px] min-w-[300px] w-[300px] max-h-[400px] rounded-xl overflow-hidden"
            variants={fadeInAnimationVariants}
            initial={{ opacity: 0}}
            whileInView={{ opacity: 1}}
            transition={{ duration: 1 }}
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isHovered ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                className="flex flex-col justify-center items-center absolute inset-0 px-10 py-20 bg-black/80 text-white text-center gap-y-10 cursor-pointer"

            >
                <h1 className="max-sm:text-3xl text-5xl font-bold">{props.film.name.toUpperCase()}</h1>
                <p className="text-xl font-bold">
                    {Array.isArray(props.film.directors)
                        ? props.film.directors.map((dir, index) => <span key={index}>{dir.name}</span>)
                        : props.film.directors.name}
                </p>
            </motion.div>
            <img src={props.film.poster} alt={`${props.film.name} Poster`} className=" object-fill h-full" />
        </motion.div>
    );
}

export default FilmCardCarousel;