import React from 'react';

interface NewsCardProps {
  link: string;
  companyName: string;
  // category: string;
  description: string;
  date: string;
  author: string; 
}

const NewsCard: React.FC<NewsCardProps> = ({
  link,
  companyName,
  // category,
  description,
  date,
  author,
}) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block group"
    >
      <div className="news-card bg-black text-white p-5 rounded-lg hover:bg-[#1d1d1d] transition duration-300 shadow-md">
        {/* Display the company name dynamically */}
        <div className="name-container mb-5 flex justify-center items-center">
          <h2 className="text-3xl font-bold text-[#d3a955] tracking-wide uppercase">
            {companyName} {/* Render dynamic company name here */}
          </h2>
        </div>

        {/* Content */}
        <div className="content text-center">
          {/* Category
          <span className="category text-sm text-[#d3a955] uppercase tracking-wider mb-3 block">
            {category}
          </span> */}

          {/* Description */}
          <p className="description text-sm text-gray-400 leading-relaxed mb-4">
            {description}
          </p>

          {/* Date and Author */}
          <div className="metadata text-xs text-gray-400 text-left">
          <p className="author text-white font-bold">{author}</p>
            <p className="date">{date}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default NewsCard;
